export const API_URL = "https://apothekeadmin.24livehost.com/api";
//export const API_URL = 'http://local.apotheke/api';
export const FRONT_URL = "https://apotheke.24livehost.com/api";
//export const FRONT_URL = "http://localhost:3000/api";

export const KlarnaDetails = {
  apiKey: "94a4f81c-b7e2-4886-b7a6-71839c439ffa",
  apiSecret:
    "klarna_test_api_WlZhWmIxWlByL2xiM1o5cld2VjhoZUk4RTZBdVFEJEIsOTRhNGY4MWMtYjdlMi00ODg2LWI3YTYtNzE4MzljNDM5ZmZhLDEsczhNaDgwMnJlbk40NWp3WUtwc1d5YVFZQnI4dzRjYmUxdUV4RWFoTlpEMD0",
  apiURL: "https://api.playground.klarna.com",
  //apiURL: "https://api.klarna.com",
  scriptUrl: 'https://cdn.klarna.com/checkout.js'
};

export const paypalDetails = {
  clientId:
    "AXa_OWYfO1hFf2fEgxsWUlY1dMzHyADimpVgKmFAksp0yqR7mxMb-gILTTXebDXh75cz69RqFWI0VM0o",
  clientSecret:
    "EOYswH_9yLmGi58uuMIDdIwIbrnrGYzLxCMmKVpal97ZdfUCLE_e6OSNcNNuvvNoX5ukiylcrjOd-1jE",
  baseUrl: 'https://api-m.sandbox.paypal.com'  
};
