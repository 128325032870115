import React, { useState } from "react";
import Translations from "../Common/Translations";
import { Typography, Grid, Box, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import ProductItem from "./ProductItem";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

function SimilarProduct(props) {
  const { products, title } = props;
  const [imageIndex, setImageIndex] = useState(0);

  const { t } = useTranslation();

  const handlePrevImage = () => {
    setImageIndex((prev) => (prev === 0 ? products?.length - 1 : prev - 1));
  };

  const handleNextImage = () => {
    setImageIndex((prev) => (prev === products?.length - 1 ? 0 : prev + 1));
  };

  return (
    <>
      <Grid className="bopc_wrap">
        <Grid>
          <Typography variant="h2">
            {title ? t(title) : <Translations text="Similar Products" />}
          </Typography>
        </Grid>
        {products?.length > 0 && (
          <Grid>
            <div className="navigation-wrapper blogSlider">
              <Box className="keen-slider">
                <div className="keen-slider__slide">
                  <ProductItem
                    product={products[imageIndex]}
                    showBrand={false}
                    showPrice={false}
                    productClass="product_bx"
                    slug={products[imageIndex]?.slug}
                    link={`/shop/${products[imageIndex]?.category?.parent?.slug}/${products[imageIndex]?.category?.slug}/${products[imageIndex]?.slug}`}
                  />
                </div>
              </Box>

              <IconButton
              className="bsal"
                onClick={handlePrevImage}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "10px",
                  transform: "translateY(-50%)",
                  color: "#0f3f83",
                  padding: "8px 4px 8px 12px",
                }}
              >
                <ArrowBackIos />
              </IconButton>
              <IconButton
              className="bsar"
                onClick={handleNextImage}
                sx={{
                  position: "absolute",
                  top: "50%",
                  right: "10px",
                  transform: "translateY(-50%)",
                  color: "#0f3f83",
                }}
              >
                <ArrowForwardIos />
              </IconButton>
            </div>
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default SimilarProduct;
