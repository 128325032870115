import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const CategoryItem = (props) => {
  const { product, buttonText, productClass } = props;
  const { t } = useTranslation();

  return (
    <>
      <Box className={productClass}>
        <Box
          component="img"
          src={product?.banner_image || "https://via.placeholder.com/400"}
          alt={t("Coming Soon")}
          sx={{ width: "100%", height: "auto", marginBottom: 4 }}
        />
        <div className="flex-grow">
          <Typography
            variant="h4"
            sx={{
              textAlign: "left",
              fontWeight: 700,
              fontSize: "16px",
              mb: "3px",
            }}
          >
            <NavLink to={`/product-category/${product?.slug}`}>
              {product.name}
            </NavLink>
          </Typography>
          {product?.children?.length > 0 && (
            <ul>
              {product?.children?.map((quality, i) => (
                <li key={i}>
                  <NavLink to={`/product-category/${product?.slug}/${quality?.slug}`}>
                    <svg viewBox="0 0 22 22" fill="currentColor">
                      <path d="M11,0H22a0,0,0,0,1,0,0V11A11,11,0,0,1,11,22h0A11,11,0,0,1,0,11v0A11,11,0,0,1,11,0Z"></path>
                    </svg>{" "}
                    <span>{quality?.name}</span>
                  </NavLink>
                </li>
              ))}
            </ul>
          )}
        </div>
        <Button
          href={`/product-category/${product?.slug}`}
          variant="text"
          fullWidth
          sx={{ justifyContent: "left" }}
        >
          {buttonText ? buttonText : `${t("Show all")} >`}
        </Button>
      </Box>
    </>
  );
};

export default CategoryItem;
