import { Box, Button, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { clearUser, userDetail } from "../../../src/features/user/userSlice";
import { logout } from "../../features/auth/authSlice";
import { Icon } from "@iconify/react";
import { sleep, formatDate } from "../../utils/util";
import OrderProgress from "./OrderProgress";

const Sidebar = (props) => {
  const { orderData } = props;
  const { t } = useTranslation();
  const location = useLocation();
  const user = useSelector((state) => state.user);
  const { userData } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [billingAddress,setBillingAddress] = useState('')
  const [shippingAddress,setShippingAddress] = useState('')

  useEffect(()=>{
    if(orderData?.order_no){
      
      const billing = (orderData?.billing_address !== '' && orderData?.billing_address !== undefined && orderData?.billing_address !== null) ? JSON.parse(orderData?.billing_address) : ""
      const shipping = orderData?.shipping_address ? JSON.parse(orderData?.shipping_address) : ''
      setBillingAddress(billing)
      setShippingAddress(shipping)
    }
  },[orderData])

  const handleLogout = () => {
    try {
      dispatch(clearUser());
      dispatch(logout());
      window.localStorage.removeItem("cartData");
      window.localStorage.removeItem("voucherData");
      toast.success(t("Logout successful")); // Use translation for success message
      navigate("/");
    } catch (error) {
      toast.error(t("Failed to logout")); // Use translation for error message
    }
  };

  const fetchData = async () => {
    try {
      await sleep(1000);
      dispatch(userDetail());
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
  }, [dispatch]);
  return (
    <Box className="dashboard_sidebar">
      {/* Name Box */}
      {(location?.pathname == ("/my-account/dashboard")|| location?.pathname == ("/my-account/dashboard/")) ? null : (
        <Box className="backtoaccount">
          <Typography variant="body2">
            <NavLink to="/my-account/dashboard">
              <Icon icon="lets-icons:back" /> {t("Back to account")}
            </NavLink>{" "}
            {/* Use translation for link text */}
          </Typography>
        </Box>
      )}
      <Box
        className="profile_box"
        display="flex"
        alignItems="center"
        mb={2.5}
        sx={{ gap: "1rem" }}
      >
        <Box className="profile_avatar">
          {user
            ? `${user?.first_name?.charAt(0)}.${user?.last_name?.charAt(0)}`
            : "N/A"}
        </Box>
        <Box>
          <Typography variant="h6" className="profile_username">
            {user ? `${user?.first_name} ${user?.last_name}` : "N/A"}
          </Typography>
          <Typography className="profile_useremail" variant="body2">
            {user ? user?.email : "N/A"}
          </Typography>
          <Button className="logout_btn" color="primary" onClick={handleLogout}>
            {t("Log Out")} {/* Use translation for button text */}
          </Button>
        </Box>
      </Box>
      {orderData?.order_no && (
        <Box className="profile_status"
        sx={{ mb: 2.5 }}>
          <Box>
            <OrderProgress orderStatus={orderData?.order_status} />
            <Box className="od_status">
              <Typography sx={{ fontWeight: 600, mb: "1.11rem" }}>{t("Order Details")}</Typography>
              <Box>
                <Typography sx={{ fontWeight: 600 }}>
                  {shippingAddress?.first_name
                    ? `${shippingAddress?.first_name} ${shippingAddress?.last_name}`
                    : `${user?.first_name} ${user?.last_name}`}
                </Typography>
                <Typography>
                  {t("Placed on")}{" "}
                  <span style={{ fontWeight: 600 }}>{formatDate(orderData?.created_at, "dd/MM/yyyy, h:mm:ss a")}</span>
                </Typography>
              </Box>
              <Divider sx={{ margin: "1rem 0" }} />
            </Box>
            {shippingAddress?.address && (
              <>
                <Box>
                  <Typography>
                    {shippingAddress?.first_name
                      ? `${shippingAddress?.first_name} ${shippingAddress?.last_name}`
                      : `${user?.first_name} ${user?.last_name}`}
                  </Typography>

                  <Typography>
                    {shippingAddress?.house_no}{" "}
                    {shippingAddress?.address}
                  </Typography>
                  {shippingAddress?.postal_code && (
                    <Typography>
                      {shippingAddress?.postal_code}
                    </Typography>
                  )}
                  {shippingAddress?.country && (
                    <Typography>
                      {shippingAddress?.country}
                    </Typography>
                  )}
                </Box>
                <Divider />
              </>
            )}
            {billingAddress?.address && (
              <>
                <Box>
                  <Typography>
                    {billingAddress?.first_name
                      ? `${billingAddress?.first_name} ${billingAddress?.last_name}`
                      : `${user?.first_name} ${user?.last_name}`}
                  </Typography>

                  <Typography>
                    {billingAddress?.house_no}{" "}
                    {billingAddress?.address}
                  </Typography>
                  {billingAddress?.postal_code && (
                    <Typography>
                      {billingAddress?.postal_code}
                    </Typography>
                  )}
                  {billingAddress?.country && (
                    <Typography>
                      {billingAddress?.country}
                    </Typography>
                  )}
                </Box>
                <Divider />
              </>
            )}
          </Box>
        </Box>
      )}
      {/* Address Box */}
      {!location?.pathname?.includes("my-account/dashboard/addresses") && (
        <Box
          className="profile_address"
          sx={{
            mb: 2.5,
          }}
        >
          <Typography variant="h6">{t("Your main address")}</Typography>
          {userData?.data?.default_address?.address && (
            <>
              <Typography variant="body1">
                {userData?.data?.default_address?.first_name}
              </Typography>
              <Typography variant="body1">
                {userData?.data?.default_address?.last_name}
              </Typography>
              <Typography variant="body1">
                {userData?.data?.default_address?.house_no}
              </Typography>
              <Typography variant="body1">
                {userData?.data?.default_address?.address}{" "}
                {userData?.data?.default_address?.city}
              </Typography>
              <Typography variant="body1">
                {userData?.data?.default_address?.postal_code}{" "}
              </Typography>
              {userData?.data?.default_address?.phone && (
                <Typography variant="body1">
                  <Icon icon="solar:phone-bold" fontSize={20} />{" "}
                  {userData?.data?.default_address?.phone}
                </Typography>
              )}
            </>
          )}{" "}
          {/* Use translation for text */}
          <Typography
            variant="body2"
            color="primary"
            sx={{ cursor: "pointer", mt: "7px" }}
          >
            <NavLink to="/my-account/dashboard/addresses">
              {t("Show all addresses")}
            </NavLink>{" "}
            {/* Use translation for link text */}
          </Typography>
        </Box>
      )}
      {/* Chat Now Box */}
      <Box className="profile_address chatnow_bx">
        <Typography variant="h6">{t("You need help")}?</Typography>{" "}
        {/* Use translation for text */}
        <Button
          variant="contained"
          className="chatnow_btn"
          id="customIntercomButton"
        >
          <Icon icon="ri:chat-4-fill" />
          {t("Chat Now")} {/* Use translation for button text */}
        </Button>
      </Box>
    </Box>
  );
};

export default Sidebar;
