import {
  Box,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import Translations from "./Translations";
import { getCartApi } from "../../features/product/productApi";
function Footer() {
  const { t } = useTranslation();
  const { categories } = useSelector((state) => state?.common);
  const { userData } = useSelector((state) => state.user || '');

  useEffect(() => {
    (async () => {
      try {
        const currentCart = window.localStorage.getItem("cartData")
        if (userData?.data?.id && (currentCart === null || currentCart === undefined || currentCart === '')) {
          const cartData = await getCartApi();
          if (cartData?.data?.cart_data) {
            window.localStorage.setItem("cartData",cartData?.data?.cart_data);
            window.dispatchEvent(new Event("cartUpdate"));
          }
        }
      } catch (error) {}
    })();
  }, [userData?.data?.id]);

  return (
    <footer
      className="footer_wrap"
      style={{ background: "#ddf0ff", padding: "32px 0px" }}
    >
      <Container maxWidth={"xl"}>
        <Grid container sx={{ gap: "2rem", justifyContent: "space-between" }}>
          <Grid item xs={12} sm={2.4} md={3} className="footer_logo">
            <Box>
              <a href="/">
                <img
                  src="/images/apotheke-premium-logo.webp"
                  alt=""
                  width={200}
                />
              </a>
              <div style={{ marginTop: "20px" }}>
                <img
                  src="/images/LogoSancoInternet_NL.webp"
                  alt=""
                  width={200}
                />
              </div>
            </Box>
          </Grid>
          <Grid className="footer_col" item xs={12} md={3}>
            <Typography
              component={"h3"}
              sx={{ color: "#0f3f83", fontWeight: "600" }}
            >
              <Translations text="Navigation" />
            </Typography>
            {categories?.data?.length && (
              <List>
                {categories?.data?.map((category, index) => (
                  <ListItem key={`fmenucat${index}`}>
                    <NavLink
                      to={category?.children?.length> 0 ? `/product-category/${category?.slug}`: `/product-category/${category?.slug}/${category?.id}`}
                      alt={category?.name}
                    >
                      {category?.name}
                    </NavLink>
                  </ListItem>
                ))}
              </List>
            )}
          </Grid>
          <Grid className="footer_col" item xs={12} md={3}>
            <Typography
              component={"h3"}
              sx={{ color: "#0f3f83", fontWeight: "600" }}
            >
              <Translations text="Other" />
            </Typography>
            <List>
              <ListItem>
                <NavLink to="/blog" alt={`${t("Blog")}`}>
                  <Translations text="Blog" />
                </NavLink>
              </ListItem>
              <ListItem>
                <NavLink to="/contact-us" alt={`${t("Contact us")}`}>
                  <Translations text="Contact us" />
                </NavLink>
              </ListItem>
              {/* <ListItem>
                <Link href="#" alt={`${t("Site map")}`}>
                  <Translations text="Site Map" />
                </Link>
              </ListItem> */}
            </List>
          </Grid>
          <Grid className="footer_col" item xs={6} md={3}>
            <Typography
              component={"h3"}
              sx={{ color: "#0f3f83", fontWeight: "600" }}
            >
              <Translations text="Legal" />
            </Typography>
            <List>
              <ListItem>
                <NavLink
                  to="/terms-and-conditions"
                  alt={`${t("Terms and Conditions")}`}
                >
                  <Translations text="Terms and Conditions" />
                </NavLink>
              </ListItem>
              <ListItem>
                <NavLink
                  to="/refund-returns"
                  alt={`${t("Delivery & Returns")}`}
                >
                  <Translations text="Delivery & Returns" />
                </NavLink>
              </ListItem>
              <ListItem>
                <NavLink to="/privacy-policy" alt={`${t("Privacy Policy")}`}>
                  <Translations text="Privacy Policy" />
                </NavLink>
              </ListItem>
              <ListItem>
                <NavLink to="/legal-notice" alt={`${t("imprint")}`}>
                  <Translations text="Imprint" />
                </NavLink>
              </ListItem>
            </List>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ mt: "10px", display: "flex", gap: "1rem" }}
            className="footer_brands"
          >
            <Typography>
              <img src="/images/klarna.svg" alt="" />
            </Typography>
            <Typography>
              <img src="/images/paypal.svg" alt="" />
            </Typography>
            <Typography>
              <img src="/images/gpay.svg" alt="" />
            </Typography>
            <Typography>
              <img src="/images/r.svg" alt="" />
            </Typography>
            <Typography>
              <img src="/images/mastercard.svg" alt="" />
            </Typography>
            <Typography>
              <img src="/images/visa.svg" alt="" />
            </Typography>
            <Typography>
              <img src="/images/american-express.svg" alt="" />
            </Typography>
            <Typography>
              <img src="/images/dhl.svg" alt="" />
            </Typography>
            <Typography>
              <img src="/images/fedex.svg" alt="" />
            </Typography>
            <Typography>
              <img src="/images/ups.svg" alt="" />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ fontSize: "12px" }}>
              <Translations text="All brands and registered trademarks are the property of their respective companies. Apotheke Premium.de is not an (Internet) pharmacy and does not offer, sell, deliver or dispense any medicines or other products in any other way. The product information and prices on this website do not constitute an offer from Apotheke Premium.de to you. Any use of this website and the services offered by Apotheke Premium.de is at your own risk. You are responsible for compliance with the legal provisions applicable in your country and declare that you are visiting this website on your own initiative." />
            </Typography>
            <Typography sx={{ fontSize: "12px" }}>
              <Translations text="Your personal information will be used to process your order, support your experience throughout this website, and for other purposes described in our privacy policy ." />
            </Typography>
            <Divider
              color="#ffffff"
              sx={{ mt: "20px", backgroundColor: "#000" }}
            />
          </Grid>
          <Grid item xs={12} sx={{ fontSize: "14px" }}>
            © {new Date().getFullYear()} Apotheke Premium
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
}

export default Footer;
