import { Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { productSearch } from "../../features/common/commonSlice";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import OfferNotificationBar from "../Common/OfferNotificationBar";
import Pagination from "../Common/Pagination";
import ProductItem from "../Products/ProductItem";

const SearchList = () => {
  const { t } = useTranslation();
  let [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { searchProducts } = useSelector((state) => state?.common);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(12);
  const [total, setTotal] = useState(0);
  const [count, setCount] = useState(0);
  const [lastPage, setLastPage] = useState(0);

  const fetchData = (page) => {
    (async () => {
      try {
        const query = `search=${searchParams.get("query")}&page=${page}&perPage=${limit}`;
        dispatch(productSearch(query)).unwrap();
      } catch (error) {}
    })();
  };

  useEffect(() => {
    if (searchProducts?.data?.data?.length > 0) {
      setPage(searchProducts?.data?.current_page);
      setLimit(searchProducts?.data?.per_page);
      setTotal(searchProducts?.data?.total);
      setCount(searchProducts?.data?.data?.length);
      setLastPage(searchProducts?.data?.last_page);
    }else{
      
      setPage(searchProducts?.data?.current_page);
      setLimit(searchProducts?.data?.per_page);
      setTotal(searchProducts?.data?.total);
      setCount(searchProducts?.data?.data?.length);
      setLastPage(searchProducts?.data?.last_page);
    }
  }, [searchProducts]);

  useEffect(() => {
    fetchData(page);
  }, [dispatch, searchParams.get("query")]);

  const handlePageChange = (value) => {
    setPage(value);
    fetchData(value);
  };

  return (
    <>
      <OfferNotificationBar />
      <Header />
      <section className="productlist_wrap">
        <Container maxWidth={"xl"}>
          {/* Category Name */}
          <Typography variant="h1" align="center" gutterBottom>
            {t("Search results for")}: "{searchParams.get("query")}"
          </Typography>

          <Typography align="center">
            {t("Showing {{show}} of {{total}} results found", {
              show: count,
              total: total,
            })}
          </Typography>

          {/* Product Grid */}
          <Grid container spacing={4} sx={{ mt: "40px" }}>
            {searchProducts?.data?.data?.length> 0 && searchProducts?.data?.data?.map((product, index) => (
              <Grid item xs={12} sm={4} key={`product${index}`}>
                <ProductItem
                  product={product}
                  showPrice={true}
                  productClass="product_bx"
                  link={`/shop/${product?.category?.parent?.slug}/${product?.category?.slug}/${product?.slug}`}
                />
              </Grid>
            ))}
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              {lastPage && total>0 && (
                <Pagination
                  count={lastPage}
                  page={page}
                  handleChange={handlePageChange}
                />
              )}
            </Grid>
          </Grid>
        </Container>
      </section>
      <Footer />
    </>
  );
};

export default SearchList;
