import React, { useState } from "react";
import Translations from "../Common/Translations";
import { Typography, Grid, IconButton } from "@mui/material";
import CategoryItem from "../Category/CategoryItem";
import "keen-slider/keen-slider.min.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

function BlogProduct(props) {
  const { products, title, buttonText } = props;
  const [imageIndex1, setImageIndex1] = useState(0);
  const [imageIndex2, setImageIndex2] = useState(1);
  const [imageIndex3, setImageIndex3] = useState(2);
  const theme = useTheme();
  const { t } = useTranslation();
  let isSm = useMediaQuery(theme.breakpoints.down("sm"));
  let isMd = useMediaQuery(theme.breakpoints.down("md"));
  const count = isSm ? 1 : isMd ? 1 : 3;

  function getCustomSubarray(n, index) {
    const result = [];
    for (let j = 0; j < count; j++) {
      result.push((index + j) % n);
    }
    return result;
  }

  const handlePrevImage = () => {
    const imd =
      imageIndex1 - count < 0
        ? products?.length + (imageIndex1 - count)
        : imageIndex1 - count;
    const arr = getCustomSubarray(products?.length, imd);
    console.log(arr, "arr");
    setImageIndex1(arr[0]);
    setImageIndex2(arr[1]);
    setImageIndex3(arr[2]);
  };

  function getNextSubarray(length, index) {
    const subarray = [];
    for (let i = index; i < length; i++) {
      for (let j = 0; j < count; j++) {
        subarray.push((i + j) % length);
      }
      break;
    }
    return subarray;
  }

  const handleNextImage = () => {
    const index =
      count === 1 ? imageIndex1 : count === 2 ? imageIndex2 : imageIndex3;
    const imd = index + 1 === products?.length ? 0 : index + 1;
    const arr = getNextSubarray(products?.length, imd);
    setImageIndex1(arr[0]);
    setImageIndex2(arr[1]);
    setImageIndex3(arr[2]);
  };

  return (
    <>
      <Grid className="bopc_wrap">
        <Grid>
          <Typography variant="h2">
            {title ? (
              t(title)
            ) : (
              <Translations text="Browse our product categories" />
            )}
          </Typography>
        </Grid>
        {products?.length > 0 && (
          <Grid>
            <div className="navigation-wrapper blogSlider">
              <div className="keen-slider" style={{ display: "flex" }}>
                {count === 1 && (
                  <>
                    <div className="keen-slider__slide">
                      {products[imageIndex1] && (
                        <CategoryItem
                          product={products[imageIndex1]}
                          buttonText={buttonText}
                          productClass="product_bx"
                        />
                      )}
                    </div>
                  </>
                )}
                {count === 2 && (
                  <>
                    <div className="keen-slider__slide">
                      {products[imageIndex1] && (
                        <CategoryItem
                          product={products[imageIndex1]}
                          buttonText={buttonText}
                          productClass="product_bx"
                        />
                      )}
                    </div>
                    <div className="keen-slider__slide">
                      {products[imageIndex2] && (
                        <CategoryItem
                          product={products[imageIndex2]}
                          buttonText={buttonText}
                          productClass="product_bx"
                        />
                      )}
                    </div>
                  </>
                )}
                {count === 3 && (
                  <>
                    <div className="keen-slider__slide">
                      {products[imageIndex1] && (
                        <CategoryItem
                          product={products[imageIndex1]}
                          buttonText={buttonText}
                          productClass="product_bx"
                        />
                      )}
                    </div>
                    <div className="keen-slider__slide">
                      {products[imageIndex2] && (
                        <CategoryItem
                          product={products[imageIndex2]}
                          buttonText={buttonText}
                          productClass="product_bx"
                        />
                      )}
                    </div>
                    <div className="keen-slider__slide">
                      {products[imageIndex3] && (
                        <CategoryItem
                          product={products[imageIndex3]}
                          buttonText={buttonText}
                          productClass="product_bx"
                        />
                      )}
                    </div>
                  </>
                )}
              </div>
              <IconButton
              className="bsal"
                onClick={handlePrevImage}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "10px",
                  transform: "translateY(-50%)",
                  color: "#0f3f83",
                  padding: "8px 4px 8px 12px",
                }}
              >
                <ArrowBackIos />
              </IconButton>
              <IconButton
              className="bsar"
                onClick={handleNextImage}
                sx={{
                  position: "absolute",
                  top: "50%",
                  right: "10px",
                  transform: "translateY(-50%)",
                  color: "#0f3f83",
                }}
              >
                <ArrowForwardIos />
              </IconButton>
            </div>
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default BlogProduct;
