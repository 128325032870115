import {Pagination} from "@mui/material";
import React, {useState, useEffect} from "react";

function PaginationCustom(props) {
  const {count, page, handleChange} = props
  const [currentPage, setCurrentPage] = useState(page);

  useEffect(()=>{
    setCurrentPage(page)
  },[page])

  const handlePageChange = (event, value) => {
    handleChange(value);
  };

  return (
    <Pagination className="pagination_box" count={(count)} page={currentPage} onChange={handlePageChange} />
  );
}

export default PaginationCustom;
