import { Icon } from "@iconify/react";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Container, IconButton, Typography, Grid } from "@mui/material";
import React from "react";
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from "react-router-dom";
import Footer from "../Common/Footer";
import Header from "../Common/Header";

function ThankYouPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const handleClose = () => {
    // Redirect to the Product Detail page and pass a flag in the state
    navigate(location.pathname, { state: { showDrawer: true, showThankYouPage: false } });
  };

  return (
    <>
      <Grid
        className="cartFaq_wrap"
        container
        sx={{
          width: "100%",
          bgcolor: "#f6faff",
          position: "relative",
          alignContent: "flex-start",
          minHeight: "100vh"
        }}
      >

        <Grid
          item
          xs={12}
          sx={{
            justifyContent: "space-between",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box className="cartFaq_logo">
            <a href="/">
              <img src="/images/apotheke-premium-logo.webp" alt="" />
            </a>
          </Box>
          <IconButton className="cartFaq_close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Grid>

        <Box className="cartFaq_success">
          <Box>
            <svg width="207" height="207" viewBox="0 0 207 207" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M81.875 103.5L96.2917 117.917L125.125 89.0833M168.375 103.5C168.375 139.329 139.329 168.375 103.5 168.375C67.6705 168.375 38.625 139.329 38.625 103.5C38.625 67.6705 67.6705 38.625 103.5 38.625C139.329 38.625 168.375 67.6705 168.375 103.5Z" stroke="#34A853" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
              <rect width="207" height="207" rx="100" fill="#34A853" fill-opacity="0.15"></rect>
            </svg>
          </Box>
          <Box>
            <Typography variant="h3">
              {t('CONGRATULATIONS!')}
            </Typography>
            <Typography variant="h3">
              {t('YOU HAVE SUCCESSFULLY COMPLETED THE MEDICAL QUESTIONNAIRE.')}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1">
              {t('Your e-prescription will now be sent to the pharmacy, which will dispense your medication after payment.')}
            </Typography>
          </Box>
          <Box>
            <Button
              className="button1"
              component={Link}
              to="/checkout" // Change this to the correct path
              variant="contained"
            >
              {t('Proceed to Payment')}
            </Button>
          </Box>
        </Box>
      </Grid>
    </>
  );
}

export default ThankYouPage;