import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { categoryListApi, faqListApi, staticPageApi, contactUsApi, blogListApi, productSearchApi, blogDetailApi } from './commonApi'; // Importing API functions

const initialState = {
  categories: null,
  subCategory: null,
  blogs: null,
  faqs: null,
  blogData: null,
  searchProducts: null,
  staticPage: null,
  status: 'idle', // 'idle' | 'pending' | 'succeeded' | 'failed'
  error: null,
  message:''
};

// Create an async thunk for reset-password
export const categoryList = createAsyncThunk(
  'common/categoryList',
  async (thunkAPI) => {
    try {
      const data = await categoryListApi(); // Calling the API function
      return data; // Assuming the response contains user data
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const subCategoryList = createAsyncThunk(
  'common/subCategoryList',
  async (slug,thunkAPI) => {
    try {
      const data = await categoryListApi(slug); // Calling the API function
      return data; // Assuming the response contains user data
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const faqList = createAsyncThunk(
  'common/faqList',
  async (thunkAPI) => {
    try {
      const data = await faqListApi(); // Calling the API function
      return data; // Assuming the response contains user data
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const staticPageData = createAsyncThunk(
  'common/staticPage',
  async (slug, thunkAPI) => {
    try {
      const data = await staticPageApi(slug); // Calling the API function
      return data; // Assuming the response contains user data
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const contactUsForm = createAsyncThunk(
  'common/contactUsForm',
  async (contactData, thunkAPI) => {
    try {
      const data = await contactUsApi(contactData); // Calling the API function
      return data; // Assuming the response contains user data
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const blogsList = createAsyncThunk(
  'common/blogsList',
  async (page,thunkAPI) => {
    try {
      const data = await blogListApi(page); // Calling the API function
      return data; // Assuming the response contains user data
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const productSearch = createAsyncThunk(
  'common/productSearch',
  async (query,thunkAPI) => {
    try {
      const data = await productSearchApi(query); // Calling the API function
      return data; // Assuming the response contains user data
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const blogDetail = createAsyncThunk(
  'common/blogDetail',
  async (slug,thunkAPI) => {
    try {
      const data = await blogDetailApi(slug); // Calling the API function
      return data; // Assuming the response contains user data
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const commonSlice = createSlice({
  name: 'common',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(categoryList.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(categoryList.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.categories = action.payload;
      })
      .addCase(categoryList.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(subCategoryList.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(subCategoryList.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.subCategory = action.payload;
      })
      .addCase(subCategoryList.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(faqList.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(faqList.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.faqs = action.payload;
      })
      .addCase(faqList.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(staticPageData.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(staticPageData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.staticPage = action.payload;
      })
      .addCase(staticPageData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(contactUsForm.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(contactUsForm.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.message= action?.payload?.message
      })
      .addCase(contactUsForm.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(blogsList.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(blogsList.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.blogs = action.payload;
      })
      .addCase(blogsList.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(productSearch.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(productSearch.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.searchProducts = action.payload;
      })
      .addCase(productSearch.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(blogDetail.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(blogDetail.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.blogData = action.payload;
      })
      .addCase(blogDetail.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export const { category } = commonSlice.actions;
export default commonSlice.reducer;
