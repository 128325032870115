import React, {useState, useEffect} from "react";
import { Tabs, Tab, Box } from '@mui/material'
import { useTranslation } from "react-i18next";

function samePageLinkNavigation(
  event,
) {
  if (
    event.defaultPrevented ||
    event.button !== 0 || // ignore everything but left-click
    event.metaKey ||
    event.ctrlKey ||
    event.altKey ||
    event.shiftKey
  ) {
    return false;
  }

  return true;
}

function StaticPageTabs(props) {
  const { handleTabChange, activeTab } = props
  const [value, setValue] = useState(activeTab);
  const {t} = useTranslation()

  useEffect(()=>{
    setValue(activeTab)
  },[activeTab])
  const handleChange = (event, newValue) => {
    // event.type can be equal to focus with selectionFollowsFocus.
    if (
      event.type !== 'click' ||
      (event.type === 'click' &&
        samePageLinkNavigation(event))
    ) {
      setValue(newValue);
      handleTabChange(newValue)
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs className="verticalStaticPageTabs" orientation="vertical" value={value} onChange={handleChange} aria-label="nav tabs example">
        <Tab label={`${t("Terms and Conditions")}`} />
        <Tab label={`${t("Delivery and returns")}`} />
        <Tab label={`${t("Privacy Policy")}`} />
        <Tab label={`${t("FAQ")}`} />
        <Tab label={`${t("Imprint")}`} />
      </Tabs>
    </Box>
  );
}

export default StaticPageTabs;
