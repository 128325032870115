import { Box, Container, Typography, CssBaseline } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import OfferNotificationBar from "../Common/OfferNotificationBar";
import Sidebar from "./Sidebar";
import { ordersListApi } from "../../features/user/userApi";
import toast from "react-hot-toast";
import Pagination from "../Common/Pagination";
import OrderItem from './OrderItem'
import { sleep } from "../../utils/util";

const OrderList = () => {
  const { t } = useTranslation();
  const [orderData, setOrderData] = useState([]);
  const [total, setTotal] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  const [page, setPage] = useState(1);

  const fetchData = async (page) => {
    try {
      await sleep(1000)
      const orders = await ordersListApi(page);
      if (orders.status) {
        setOrderData(orders?.data?.data);
        setPage(orders?.data?.current_page);
        setTotal(orders?.data?.total);
        setLastPage(orders?.data?.last_page);
      } else {
        toast.error(orders?.message);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchData(page);
  }, [page]);

  const handlePageChange = (value) => {
    setPage(value);
    fetchData(value);
  };

  return (
    <>
      <OfferNotificationBar />
      <Header />
      <section style={{ background: "#f6faff" }}>
        <Container className="dashboard_wrap">
          <CssBaseline />
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            <Sidebar />
            <Box className="dashboard_content">
              {orderData?.length > 0 ? (
                <>
                  <div style={{ display: "flex", flexWrap: "wrap", gap: "25px", marginTop: "16px" }}>
                    {orderData?.map((order) => (
                      <OrderItem key={order?.id} data={order} />
                    ))}
                    </div>
                    {lastPage && total > 0 && (
                      <Pagination
                        count={lastPage}
                        page={page}
                        handleChange={handlePageChange}
                      />
                    )}
                  
                </>
              ) : (
                <Typography variant="body1" sx={{ color: "#0f3f83" }}>
                  {t("You don't have any orders yet")}{" "}
                  {/* Use translation for text */}
                </Typography>
              )}
            </Box>
          </Box>
        </Container>
      </section>
      <Footer />
    </>
  );
};

export default OrderList;
