import { useEffect, forwardRef, Fragment } from 'react'

// ** MUI Imports
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import Slide from '@mui/material/Slide'
import DialogContentText from '@mui/material/DialogContentText'
import DialogContent from '@mui/material/DialogContent'
import { Icon } from "@iconify/react";
import { sleep } from '../../utils/util'

const Transition = forwardRef(function Transition(
  props,
  ref
) {
  return <Slide direction='up' ref={ref} {...props} />
})



const ConfirmDialogWithYesNoButton = (props) => {
  const { rowData, openDialogAlert, onCloseDialogAlert, confirmMessage, confirmButtonText, declineButtonText, size } = props
  const loading = true
  const dialogWidth = size || 'xs'

  const closeDialogYes = () => {
    onCloseDialogAlert('yes')
  }

  const closeDialogNo = () => {
    onCloseDialogAlert('no')
  }

  useEffect(() => {
    if (!loading) {
      return undefined
    }
    if (rowData?.rowData) {
      sleep(1000)
    }
  }, [loading, openDialogAlert, rowData])

  return (
    <Fragment>
      <Dialog
        maxWidth={dialogWidth}
        fullWidth={true}
        open={openDialogAlert}
        TransitionComponent={Transition}
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogContent sx={{ textAlign: 'center' }}>
            <Icon icon='mdi:alert' fontSize={50} color='#fbab1b'></Icon>
          <DialogContentText id='alert-dialog-slide-description'>{confirmMessage}</DialogContentText>
        </DialogContent>
        <DialogActions sx={{ margin: 'auto' }}>
          <Button variant='contained' color='primary' onClick={closeDialogYes}>
            {confirmButtonText}
          </Button>
          <Button variant='outlined' color="primary" onClick={closeDialogNo}>
            {declineButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
export default ConfirmDialogWithYesNoButton
