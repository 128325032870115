import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { resetPassword } from '../features/auth/authSlice';

function ResetPasswordConfirmation({ email }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleResendEmail = async () => {
    try {
      await dispatch(resetPassword({ email })).unwrap();
      toast.success(t('Resent password reset email')); // Use translation for success message
    } catch (error) {
      toast.error(t('Failed to resend email')); // Use translation for error message
    }
  };

  return (
    <Box>
      <div className='fyp_txt'>
        <Typography variant="h5" gutterBottom sx={{ fontWeight: 700, mb: 2.5 }}>
          {t('Check your email')} {/* Translation key */}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {t('We have sent an email with information on how to reset your password.')} {/* Translation key */}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {t("Didn't receive the email? Check your spam or promotions folder.")} {/* Translation key */}
        </Typography>
      </div>
      <div className='login_form_inner'>
        <Button variant="contained" className='button1' fullWidth onClick={handleResendEmail}>
          {t('Resend Email')} {/* Translation key */}
        </Button>
        <div className='forgotpassword'>
          <Link to="/my-account">{t('Back to Login')} {/* Translation key */}</Link>
        </div>
      </div>
    </Box>
  );
}

export default ResetPasswordConfirmation;
