import React, {useEffect} from "react";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import OfferNotificationBar from "../Common/OfferNotificationBar";
import Translations from "../Common/Translations";
import { Typography, Container, Grid, Button, Box } from "@mui/material";
import BlogProduct from "./BlogProduct";
import { useDispatch, useSelector } from "react-redux";
import SimilarProduct from "../Products/SimilarProduct";
import { blogDetail } from "../../features/common/commonSlice";
import { useParams } from "react-router-dom";

function BlogDetail() {
  const { categories,blogData } = useSelector((state) => state?.common);
  const {slug} = useParams()
  const dispatch = useDispatch();
  const fetchData = async () => {
      try {
        dispatch(blogDetail(slug)).unwrap();
      } catch (error) {}
  };
  useEffect(() => {
    fetchData();
  }, [dispatch]);

  return (
    <>
      <OfferNotificationBar />
      <Header />
      <section className="blogDetails">
        <Container maxWidth={"xl"}>
          <Grid container>
            <Grid item xs={12} sm={12} md={8}>
              <div className="bdContent">
                <Typography variant="h1">
                  {blogData?.data?.title}
                </Typography>
                <div className="bdImage">
                  <Typography sx={{ width: "100%" }}>
                    <img
                      src={blogData?.data?.banner_image || "/images/blog1.webp"}
                      alt={blogData?.data?.title}
                    />
                  </Typography>
                </div>
                <Box dangerouslySetInnerHTML={{ __html: blogData?.data?.content }}>
                </Box>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <SimilarProduct
                products={blogData?.data?.products}
                perView = {1}
              />
            </Grid>
          </Grid>
        </Container>
      </section>
      <section>
        <Container maxWidth={"xl"} sx={{ mb: "40px" }}>
          <BlogProduct products={categories?.data} perView={3} />
        </Container>
      </section>
      <section>
        <Container maxWidth={"xl"} sx={{ mb: "50px" }}>
          <Grid className="register_today">
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                alignItems: "center",
              }}
            >
              <Typography variant="h4">
                <Translations text="From ordering your medications to tracking their delivery, we make it easy." />
              </Typography>
              <Typography>
                <Translations text="Register for free today and receive your medications." />
              </Typography>
              <Button
                href="/my-account"
                variant="contained"
                className="button1"
              >
                <Translations text="Register" />
              </Button>
            </Grid>
          </Grid>
        </Container>
      </section>
      <Footer />
    </>
  );
}

export default BlogDetail;
