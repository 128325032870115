import React from "react";
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Icon } from "@iconify/react";


function FaqContent() {
  const { faqs } = useSelector((state) => state?.common);
  
  // Manage the expanded state for each accordion using index
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box className="legal_faq" sx={{ width: "100%" }}>
      {faqs?.data?.map((faq, index) => (
        <Box sx={{ width: "100%" }} key={`faqPage${index}`}>
          <Accordion 
            className="legal_faq_inner" 
            expanded={expanded === index} 
            onChange={handleChange(index)}
          >
            <AccordionSummary
              expandIcon={expanded === index ? 
                <Icon fontSize={30} icon="zondicons:minus-solid" /> : 
                <Icon fontSize={30} icon="octicon:feed-plus-16" />
              }
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
            >
              <Typography>{faq?.question}</Typography>
            </AccordionSummary>
            <AccordionDetails className="legal_faq_content">
              <Typography dangerouslySetInnerHTML={{ __html: faq?.answer }}></Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      ))}
    </Box>
  );
}

export default FaqContent;
