import axios from 'axios';
import { API_URL } from '../../config/config';
import { getUserToken } from '../../utils/util';

// Register user
export const addAddressApi = async (userData) => {

  const userTokenData = getUserToken('persist:root')
  const response = await axios.post(`${API_URL}/add-address`, userData,{
    headers: {  Authorization: `Bearer ${userTokenData?.token}` }
  });
  return response.data;
};

export const editAddressApi = async (userData) => {
  const userTokenData = getUserToken('persist:root')
  const response = await axios.post(`${API_URL}/update-address`, userData,{
    headers: {  Authorization: `Bearer ${userTokenData?.token}` }
  });
  return response.data;
};

export const deleteAddressApi = async (userData) => {
  const userTokenData = getUserToken('persist:root')
  const response = await axios.post(`${API_URL}/delete-address`, userData,{
    headers: {  Authorization: `Bearer ${userTokenData?.token}` }
  });
  return response.data;
};

export const defaultAddressApi = async (userData) => {
  const userTokenData = getUserToken('persist:root')
  const response = await axios.post(`${API_URL}/default-address`, userData,{
    headers: {  Authorization: `Bearer ${userTokenData?.token}` }
  });
  return response.data;
};

export const listAddressApi = async () => {
  const userTokenData = getUserToken('persist:root')
  const response = await axios.get(`${API_URL}/addresses`,{
    headers: {  Authorization: `Bearer ${userTokenData?.token}` }
  });
  return response.data;
};

export const userDetailApi = async () => {
  const userTokenData = await getUserToken('persist:root')
  const response = await axios.get(`${API_URL}/user-details`,{
    headers: {  Authorization: `Bearer ${userTokenData?.token}` }
  });
  return response.data;
};

export const ordersListApi = async (page) => {
  const pageQuery = page ? `?page=${page}` : ""
  const userTokenData = await getUserToken('persist:root')
  const response = await axios.post(`${API_URL}/order-list${pageQuery}`,'',{
    headers: {  Authorization: `Bearer ${userTokenData?.token}` }
  });
  return response.data;
};

export const orderDetailApi = async (orderId) => {
  const userTokenData = await getUserToken('persist:root')
  const tok = window.localStorage.getItem('tok')
  const response = await axios.post(`${API_URL}/order-details?order_id=${orderId}`,'',{
    headers: {  Authorization: `Bearer ${userTokenData?.token || tok}` }
  });
  return response.data;
};

export const orderInvoiceApi = async (orderId) => {
  const userTokenData = await getUserToken('persist:root')
  const response = await axios.get(`${API_URL}/invoice?order_id=${orderId}`,{
    headers: {  Authorization: `Bearer ${userTokenData?.token}`,'Content-Type': 'application/pdf', 'Accept': 'application/pdf',}
  });
  return response.data;
};
