import { Box, Typography, Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import OrderProgress from "./OrderProgress";
import { formatDate } from "../../utils/util";

const OrderItem = (props) => {
  const { data } = props;
  const { t } = useTranslation();
  const [orderData, setOrderData] = useState(data);

  useEffect(() => {
    setOrderData(data);
  }, [data]);

  return (
    <>
      <Box className="orderList">
        <Box className="orderList_details">
          <Box className="orderList_content">
            <Typography variant="h6" className="orderId">
              {t("Order")} # {orderData?.order_no}
            </Typography>
            <Typography className="orderDate">
              {t("Ordered on")}{" "}
              {formatDate(orderData?.created_at, "dd/MM/yyyy")}
            </Typography>
            <OrderProgress orderStatus={orderData?.order_status} />
          </Box>
          <Box className="orderList_image">
            <Box className="orderList_image_box">
              {orderData?.items?.length > 0 && (
                <>
                  {orderData?.items?.map((product, index) => (
                    <>
                      {index < 2 && (
                        <div className="orderList_img">
                          <img
                            src={product?.product?.images?.[0]?.file_path}
                            alt={product?.product?.name}
                            width={100}
                            key={`orderproduct${index}`}
                          />
                        </div>
                      )}
                    </>
                  ))}
                </>
              )}
            </Box>
            <Button
              className="view_order_btn"
              variant="contained"
              href={`/my-account/dashboard/orders/${orderData?.id}`}
            >
              {t("View Order")}
            </Button>
          </Box>
        </Box>
        <Box className="view_order_button_tablet ">
          <Button
            className="view_order_btn"
            variant="contained"
            href={`/my-account/dashboard/orders/${orderData?.id}`}
          >
            {t("View Order")}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default OrderItem;
