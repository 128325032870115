import { Box, Grid } from '@mui/material';

function AuthBanner() {
return (
<>
<Grid item sm={12} md={6} sx={{ position: "relative"}} display={{ xs: "none", sm: "none", md: "block" }}>
      <Box className="login_img"><img src='/images/login-register-bg-img.png'/></Box>
      <div className='login_content'>
        <h2>VERFOLGEN SIE IHRE BESTELLUNGEN</h2>
        <p>Verfolgen Sie den Status Ihrer Bestellungen</p>
      </div>
    </Grid>
    </>
  );
}

export default AuthBanner;