import React, {useEffect} from "react";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { staticPageData } from "../../features/common/commonSlice";

function PageContent(props) {
  const {slug} = props
  const dispatch = useDispatch();
  const { staticPage } = useSelector((state) => state?.common);

  useEffect(() => {
    (async () => {
      try {
        dispatch(staticPageData(slug)).unwrap();
      } catch (error) {}
    })();
  }, [dispatch,slug]);

  return (
    <Box className="pageContent"  dangerouslySetInnerHTML={{ __html: staticPage?.data?.content }}>
      
    </Box>
  );
}

export default PageContent;
