// src/slices/userSlice.js
import { createAsyncThunk,createSlice } from '@reduxjs/toolkit';
import { addAddressApi, editAddressApi, deleteAddressApi, listAddressApi, defaultAddressApi, userDetailApi } from './userApi';

const initialState = {
  address: null,
  userData:null,
  status: 'idle', // 'idle' | 'pending' | 'succeeded' | 'failed'
  error: null,
  message:''
};

export const addAddress = createAsyncThunk(
  'user/addAddress',
  async (userData, thunkAPI) => {
    try {
      const data = await addAddressApi(userData); // Calling the API function
      return data; // Assuming the response contains user data
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const editAddress = createAsyncThunk(
  'user/editAddress',
  async (userData, thunkAPI) => {
    try {
      const data = await editAddressApi(userData); // Calling the API function
      return data; // Assuming the response contains user data
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const deleteAddress = createAsyncThunk(
  'user/deleteAddress',
  async (userData, thunkAPI) => {
    try {
      const data = await deleteAddressApi(userData); // Calling the API function
      return data; // Assuming the response contains user data
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const defaultAddress = createAsyncThunk(
  'user/defaultAddress',
  async (userData, thunkAPI) => {
    try {
      const data = await defaultAddressApi(userData); // Calling the API function
      return data; // Assuming the response contains user data
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const listAddress = createAsyncThunk(
  'user/listAddress',
  async (userData, thunkAPI) => {
    try {
      const data = await listAddressApi(userData); // Calling the API function
      return data; // Assuming the response contains user data
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const userDetail = createAsyncThunk(
  'user/userDetail',
  async (userData, thunkAPI) => {
    try {
      const data = await userDetailApi(userData); // Calling the API function
      return data; // Assuming the response contains user data
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const userSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action) => action.payload,
    clearUser: () => null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(addAddress.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(addAddress.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.message= action?.payload?.message
      })
      .addCase(addAddress.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(editAddress.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(editAddress.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.message= action?.payload?.message
      })
      .addCase(editAddress.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(deleteAddress.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(deleteAddress.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.message= action?.payload?.message
      })
      .addCase(deleteAddress.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(defaultAddress.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(defaultAddress.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.message= action?.payload?.message
      })
      .addCase(defaultAddress.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(listAddress.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(listAddress.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.address = action.payload;
        state.message = '';
      })
      .addCase(listAddress.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
        state.message = '';
      })
      .addCase(userDetail.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(userDetail.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.userData = action.payload;
      })
      .addCase(userDetail.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
  },
});

export const { setUser, clearUser } = userSlice.actions;
export default userSlice.reducer;
