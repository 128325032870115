import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { productDetailApi, productListApi, questionsApi, addToCartApi, getCartApi } from './productApi'; // Importing API functions

const initialState = {
    products: [],
    productDetails: [],
    questions: [],
    userCartData: [],
    status: 'idle', // 'idle' | 'pending' | 'succeeded' | 'failed'
    error: null,
};

// Create an async thunk for fetching product list
export const fetchProductList = createAsyncThunk(
    'product/fetchProductList',
    async (slug, thunkAPI) => {
      try {
        const data = await productListApi(slug); // Pass slug if needed
        return data; // Assuming the response contains product data
      } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response.data : error.message);
      }
    }
);

// Create an async thunk for fetching product detail
export const fetchProductDetail = createAsyncThunk(
  'product/fetchProductDetail',
  async (slug, thunkAPI) => {
    try {
      const data = await productDetailApi(slug); // Pass slug if needed
      return data; // Assuming the response contains product data
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);

export const questionsByCategory = createAsyncThunk(
  'product/questionsByCategory',
  async (category_id, thunkAPI) => {
    try {
      const data = await questionsApi(category_id); // Pass slug if needed
      return data; // Assuming the response contains product data
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);

export const addToCart = createAsyncThunk(
  'product/addToCart',
  async (cartData, thunkAPI) => {
    try {
      const data = await addToCartApi(cartData); // Pass slug if needed
      return data; // Assuming the response contains product data
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);

export const getCart = createAsyncThunk(
  'product/getCart',
  async (cartData, thunkAPI) => {
    try {
      const data = await getCartApi(cartData); // Pass slug if needed
      return data; // Assuming the response contains product data
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);

const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchProductList.pending, (state) => {
          state.status = 'pending';
        })
        .addCase(fetchProductList.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.products = action.payload;
        })
        .addCase(fetchProductList.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.payload;
        })
        .addCase(fetchProductDetail.pending, (state) => {
          state.status = 'pending';
        })
        .addCase(fetchProductDetail.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.productDetails = action.payload;
        })
        .addCase(fetchProductDetail.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.payload;
        })
        .addCase(questionsByCategory.pending, (state) => {
          state.status = 'pending';
        })
        .addCase(questionsByCategory.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.questions = action.payload;
        })
        .addCase(questionsByCategory.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.payload;
        })
        .addCase(addToCart.pending, (state) => {
          state.status = 'pending';
        })
        .addCase(addToCart.fulfilled, (state, action) => {
          state.status = 'succeeded';
        })
        .addCase(addToCart.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.payload;
        })
        .addCase(getCart.pending, (state) => {
          state.status = 'pending';
        })
        .addCase(getCart.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.userCartData = action.payload;
        })
        .addCase(getCart.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.payload;
        })
    },
});

export default productSlice.reducer;
