import { Box, Button, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import AuthBanner from '../../src/components/AuthBanner';
import Footer from '../../src/components/Common/Footer';
import Header from '../../src/components/Common/Header';
import OfferNotificationBar from '../../src/components/Common/OfferNotificationBar';
import { resetPassword } from '../features/auth/authSlice';
import ResetPasswordConfirmation from './ResetPasswordConfirmation';

function ForgotPassword() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { status } = useSelector((state) => state.auth);
  const { control, handleSubmit, formState: { errors } } = useForm();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [email, setEmail] = useState('');

  const onSubmit = async (data) => {
    try {
      await dispatch(resetPassword(data)).unwrap();
      toast.success(t('Password reset email sent successfully')); // Translation key
      setEmail(data.email);
      setIsSubmitted(true);
    } catch (error) {
      toast.error(t('Failed to reset password')); // Translation key
    }
  };

  return (
    <>
      <OfferNotificationBar />
      <Header />
      <Grid container>
        <AuthBanner />
        <Grid item sm={12} md={6} bgcolor={"rgb(238, 247, 254)"} className='login_right'>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {isSubmitted ? (
              <ResetPasswordConfirmation email={email} />
            ) : (
              <>
                <div className='fyp_txt'>
                  <Typography variant="h6" gutterBottom>
                    {t('Forgot Your Password')} {/* Translation key */}
                  </Typography>
                  <Typography variant='p'>
                    {t('Please enter your e-mail address or your username. You will receive a link to create a new password via e-mail.')} {/* Translation key */}
                  </Typography>
                </div>
                <Box className='login_form_inner'
                  component="form"
                  noValidate
                  autoComplete="off"
                  sx={{ width: '100%' }}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    rules={{ required: t('Username or email address is required') }} // Translation key
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={t('Username or email address')} // Translation key
                        fullWidth
                        margin="normal"
                        required
                        error={!!errors.email}
                        helperText={errors.email?.message}
                      />
                    )}
                  />
                  <Button className='button1'
                    variant="contained"
                    fullWidth
                    type="submit"
                    disabled={status === 'pending'}
                  >
                    {status === 'pending' ? <CircularProgress size={24} /> : t('Reset Password')} {/* Translation key */}
                  </Button>
                  <div className='forgotpassword'>
                    <Link to="/my-account">
                      {t('Back to Login')} {/* Translation key */}
                    </Link>
                  </div>
                </Box>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
      <Footer />
    </>
  );
}

export default ForgotPassword;