import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Default storage (localStorage)
import authReducer from '../features/auth/authSlice';
import commonReducer from '../features/common/commonSlice';
import productReducer from '../features/product/productSlice';
import userReducer from '../features/user/userSlice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'user','product'], // Reducers you want to persist
};

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  common: commonReducer,
  product: productReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
