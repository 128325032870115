import { Box, LinearProgress, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const OrderProgress = (props) => {
  const { orderStatus } = props;
  const { t } = useTranslation();
  const [progress, setProgress] = useState('');
  const [status, setStatus] = useState(orderStatus);
  const progressItem = {
    processing: 33,
    shipped: 67,
    delivered: 100,
    canceled: 10,
  }

  useEffect(() => {
    setStatus(orderStatus);
    setProgress(progressItem?.[orderStatus])
  }, [orderStatus]);

  return (
    <>
      <Box className="order_progress">
        <Typography variant="h6">{t("status")}</Typography>
        <LinearProgress sx={{ height: "10px", background: "#b7c5da", borderRadius: "30px" }} variant="determinate" value={progress} />
        <Typography>
          {status}
        </Typography>
      </Box>
    </>
  );
};

export default OrderProgress;
