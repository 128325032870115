import axios from 'axios';
import { API_URL } from '../../config/config';

// Register user
export const registerUserApi = async (userData) => {
  const response = await axios.post(`${API_URL}/register`, userData,{timeout: 30000} );
  return response.data;
};

//Login user
export const loginUserApi = async (userData) => {
  const response = await axios.post(`${API_URL}/login`, userData);
  return response.data;
};

//ResetPassword
export const resetPasswordApi = async (email) => {
  const response = await axios.post(`${API_URL}/reset-password`, email);
  return response.data;
};

export const updatePasswordApi = async ({ token, email,password,confirm_password }) => {
  const response = await axios.post(`${API_URL}/password-reset`, { token, email, password,confirm_password });
  return response.data;
};
