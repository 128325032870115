import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const ProductItem = (props) => {
  const { product, showPrice, productClass, link, showBrand } = props;
  const { t } = useTranslation();
  const points = product?.bullets ? JSON.parse(product?.bullets) : [];

  // Get the first image from the product.images array
  const productImage = product?.images?.[0]?.file_path || "";

  return (
    <Box className={productClass}>
      <Box
        className="product_img"
        component="img"
        src={productImage}
        alt={product?.name}
        sx={{ width: "100%", height: "auto", marginBottom: 2 }}
      />
      <div className="flex-grow">
        <Typography
          variant="h4"
          sx={{
            textAlign: "left",
            fontWeight: 700,
            fontSize: "16px",
            mb: "3px",
          }}
        >
          {product?.name}
        </Typography>
        {showBrand && (
          <Typography sx={{ textAlign: "left", mb: "2px" }}>
            {t("Brand")}: {product?.brand?.name}
          </Typography>
        )}
        <ul>
          {points.map((point, i) => (
            <li key={i}>
              <svg viewBox="0 0 22 22" fill="currentColor">
                <path d="M11,0H22a0,0,0,0,1,0,0V11A11,11,0,0,1,11,22h0A11,11,0,0,1,0,11v0A11,11,0,0,1,11,0Z"></path>
              </svg>{" "}
              <span>{t(point)}</span>
            </li>
          ))}
        </ul>
      </div>
      {showPrice && (
        <>
          <Typography
            sx={{
              textAlign: "left",
              fontSize: "18px",
              fontWeight: 700,
              mb: "1rem",
            }}
          >
            {t("From")} €{product.product_price}
          </Typography>
        </>
      )}
      <Link to={link}>
        <Button className="button1" fullWidth>
          {t("View")}
        </Button>
      </Link>
    </Box>
  );
};

export default ProductItem;
