import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, Button, Checkbox, CircularProgress, FormControlLabel, FormHelperText, IconButton, InputAdornment, Link, TextField, Typography } from '@mui/material';
import { format } from 'date-fns';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { registerUser } from '../features/auth/authSlice';
import { setUser } from '../features/user/userSlice';

function Register() {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { status } = useSelector((state) => state.auth);
  const { control, handleSubmit, watch, formState: { errors } } = useForm();
  const password = watch('password', ''); // Watch password field value

  const handleClickShowPassword = () => setShowPassword((prev) => !prev);
  const handleClickShowConfirmPassword = () => setConfirmShowPassword((prev) => !prev);

  const onSubmit = async (data) => {
    try {
      // Format the date before sending
      const formattedDate = format(new Date(data.dob), 'dd/MM/yyyy');
      const requestData = { ...data, dob: formattedDate };
      const response = await dispatch(registerUser(requestData)).unwrap();
      if(response?.status){
        dispatch(setUser(response?.data));
        toast.success(t('Registered successfully')); // Use translation for success message
        navigate('/my-account/dashboard');
      }else{
        toast.error(response?.message)
      }
    } catch (error) {
      toast.error(t('Failed to register user')); // Use translation for error message
    }
  };

  return (
    <Box
      component="form"
      className="login_form_inner"
      noValidate
      autoComplete="off"
      sx={{ width: '100%' }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Controller
        name="first_name"
        control={control}
        defaultValue=""
        rules={{ required: t('First name is required') }}
        render={({ field }) => (
          <TextField
            {...field}
            label={t('First name')}
            fullWidth
            margin="normal"
            error={!!errors.first_name}
            helperText={errors.first_name?.message}
          />
        )}
      />

      <Controller
        name="last_name"
        control={control}
        defaultValue=""
        rules={{ required: t('Last name is required') }}
        render={({ field }) => (
          <TextField
            {...field}
            label={t('Last name')}
            fullWidth
            margin="normal"
            error={!!errors.last_name}
            helperText={errors.last_name?.message}
          />
        )}
      />

      <Controller
        name="email"
        control={control}
        defaultValue=""
        rules={{ required: t('Username or email address is required') }}
        render={({ field }) => (
          <TextField
            {...field}
            label={t('Username or email address')}
            fullWidth
            margin="normal"
            error={!!errors.email}
            helperText={errors.email?.message}
          />
        )}
      />

      <Controller
        name="password"
        control={control}
        defaultValue=""
        rules={{ required: t('Password is required') }}
        render={({ field }) => (
          <TextField
            {...field}
            label={t('Password')}
            type={showPassword ? 'text' : 'password'}
            fullWidth
            margin="normal"
            error={!!errors.password}
            helperText={errors.password?.message}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label={t('toggle password visibility')}
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      />

      <Controller
        name="confirm_password"
        control={control}
        defaultValue=""
        rules={{
          required: t('Confirm password is required'),
          validate: (value) => value === password || t('Passwords do not match'),
        }}
        render={({ field }) => (
          <TextField
            {...field}
            label={t('Confirm Password')}
            type={showConfirmPassword ? 'text' : 'password'}
            fullWidth
            margin="normal"
            error={!!errors.confirm_password}
            helperText={errors.confirm_password?.message}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label={t('toggle password visibility')}
                    onClick={handleClickShowConfirmPassword}
                    edge="end"
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      />

      <Controller
        name="dob"
        control={control}
        defaultValue=""
        rules={{ required: t('Date of birth is required') }}
        render={({ field }) => (
          <TextField
            {...field}
            label={t('Birth date')}
            type="date"
            fullWidth
            margin="normal"
            error={!!errors.dob}
            helperText={errors.dob?.message}
            InputLabelProps={{ shrink: true }}
          />
        )}
      />

      <Controller
        name="agree"
        control={control}
        defaultValue={false}
        rules={{ required: t('Agreement is required') }}
        render={({ field }) => (
          <FormControlLabel
            control={<Checkbox {...field} checked={field.value} />}
            label={
              <Typography>
                {t('I agree to the')}{' '}
                <Link
                  sx={{ color: '#0f3f83', fontWeight: 600, textDecoration: 'underline' }}
                  href="/privacy-policy"
                  target="_blank"
                  rel="noopener"
                >
                  {t('Privacy Policy')}
                </Link>
                ,{' '}
                <Link
                  sx={{ color: '#0f3f83', fontWeight: 600, textDecoration: 'underline' }}
                  href="/refund-returns"
                  target="_blank"
                  rel="noopener"
                >
                  {t('Refund Policy')}
                </Link>
                , {t('and')}{' '}
                <Link
                  sx={{ color: '#0f3f83', fontWeight: 600, textDecoration: 'underline' }}
                  href="/terms-and-conditions"
                  target="_blank"
                  rel="noopener"
                >
                  {t('Terms and Conditions')}
                </Link>
              </Typography>
            }
          />
        )}
      />

      {errors.agree && <FormHelperText error>{errors.agree.message}</FormHelperText>}

      <Controller
        name="promotional_consent"
        control={control}
        defaultValue={false}
        render={({ field }) => (
          <FormControlLabel
            control={<Checkbox {...field} checked={field.value} />}
            label={t('You give Apotheke Premium permission to use your information to send you updates and discount codes.')}
          />
        )}
      />

      <Button
        className='button1'
        variant="contained"
        size="large"
        fullWidth
        type="submit"
        disabled={status === 'pending'}
      >
        {status === 'pending' ? <CircularProgress size={24} /> : t('Register')}
      </Button>
    </Box>
  );
}

export default Register;
